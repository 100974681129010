import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const getGoal = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'goal',
    method: 'get'
  })

  return data
}

const putGoal = async ({ requestBody, isCustomized = false }) => {
  const custom = isCustomized ? '?custom=true' : ''

  const { data } = await defaultLeadsApiCall({
    endpoint: `goal/${custom}`,
    method: 'put',
    body: requestBody
  })

  return data
}

export default { getGoal, putGoal }
