export const goalDictionary = {
  'buy-house': 'ComprarCasa',
  'make-purchase': 'FazerUmaCompra',
  'medical-costs': 'CobrirCustosMedicos',
  'take-trip': 'FazerUmaViagem',
  'pay-debt': 'PagarUmaConta',
  'buy-car': 'ComprarUmCarro',
  'invest-education': 'InvestirEmEducacao',
  others: 'Outros'
}

export const goals = [
  {
    label: 'Comprar uma casa',
    id: 'buy-house',
    value: 'buy-house',
    icon: {
      name: 'home',
      type: 'outlined'
    }
  },
  {
    label: 'Fazer uma compra',
    id: 'make-purchase',
    value: 'make-purchase',
    icon: {
      name: 'presente',
      type: 'outlined'
    }
  },
  {
    label: 'Cobrir custos médicos',
    id: 'medical-costs',
    value: 'medical-costs',
    icon: {
      name: 'consorcio',
      type: 'outlined'
    }
  },
  {
    label: 'Fazer uma viagem',
    id: 'take-trip',
    value: 'take-trip',
    icon: {
      name: 'viagem',
      type: 'outlined'
    }
  },
  {
    label: 'Pagar uma dívida',
    id: 'pay-debt',
    value: 'pay-debt',
    icon: {
      name: 'documento',
      type: 'outlined'
    }
  },
  {
    label: 'Comprar um carro',
    id: 'buy-car',
    value: 'buy-car',
    icon: {
      name: 'consorcio-auto',
      type: 'outlined'
    }
  },
  {
    label: 'Investir em educação',
    id: 'invest-education',
    value: 'invest-education',
    icon: {
      name: 'universitarios',
      type: 'outlined'
    }
  },
  {
    label: 'Outros',
    id: 'others',
    value: 'others',
    icon: {
      name: 'favorito',
      type: 'outlined'
    }
  }
]
