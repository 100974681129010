import { useEffect, useState } from 'react'

import { VWO_GOAL_EXPERIMENT } from 'gatsby-env-variables'

import { useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import { goalDictionary } from '../constants'
import services from '../services'

export const useSelectGoal = () => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [getLoading, setGetLoading] = useState(true)
  const { loading } = useProposal()
  const { changeLeadData, leadData } = useLead()

  useEffect(() => {
    analytics.track('pageLoad', {
      currentPage: 'formulario-objetivo',
      customLayer: {
        testName: VWO_GOAL_EXPERIMENT,
        testVariant: leadData?.vwoVariant
      }
    })

    const getData = async () => {
      try {
        const { previous_step } = await services.getGoal()
        changeLeadData({
          previousStep: previous_step
        })
      } catch (error) {
        const payload = {
          error: error?.message,
          requestURL: error?.request?.responseURL
        }

        DDPageError({
          name: 'useSelectGoal | getData',
          payload
        })
      } finally {
        setGetLoading(false)
      }
    }

    getData()
    console.log('goal mounted')
  }, [])

  const onSubmit = async (payload) => {
    setSubmitLoading(true)

    const { goal } = payload

    analytics.track('buttonClick', {
      currentPage: 'formulario-objetivo',
      detail: 'click-step-objetivo',
      customLayer: {
        description: `objetivo-${goalDictionary[goal]}`
      }
    })

    if (goal === 'others') {
      changeLeadData({
        previousStep: 'select-macro-goal'
      })
      goTo('select-goal-customize')
    } else {
      const requestBody = { goal }

      return services
        .putGoal({ requestBody })
        .then(({ next_step }) => {
          changeLeadData(requestBody)
          goTo(next_step)
        })
        .catch((error) => {
          const payload = {
            error: error?.message,
            requestURL: error?.request?.responseURL
          }

          DDPageError({
            name: 'useSelectGoal | onSubmit',
            payload
          })
        })
        .finally(() => setSubmitLoading(false))
    }
  }

  return { onSubmit, loading, submitLoading, getLoading }
}
